// NEED TO HAVE A BUTTON WITH ID 'FROALA_OPEN_FULLSCREEN_ID' IN THE TEMPLATE

export const openFullScreen = (editor: any) => {
  editor.$('body').on('click', 'button#preview-editor-fullscreen', function() {
    editor.fullscreen.toggle();
    if (editor.fullscreen.isActive()) {
      editor.toolbar.show(); // Ensure the toolbar is visible
      editor.$tb.prepend(`
            <div id="custom-title" class="flex justify-between items-center py-[7px] px-[21px]">
              <h2 class="text-2xl font-bold">앞서 해보기 안내 *</h2>
              <div class="flex gap-8 items-center">
                <div class="relative flex gap-4 items-center">
                  <span>다크 모드</span>
                  <button for="md" class="btn-switch-theme relative stds-switch-invisible-label h-24 w-40 rounded-[1.2rem] before:top-4 before:left-4 before:h-16 before:w-16 bg-neutral-variant-4 before:translate-x-0 cursor-pointer"><span class="sr-only"></span></button>
                </div>
                <button id="close-fullscreen" class="bg-white border border-solid border-[#818181] p-[1.2rem] rounded-lg text-black">전체 화면으로 보기 해제</button>
              </div>
            </div>
          `);
      const switcher = document.querySelector('.btn-switch-theme') as HTMLElement;
      const toolbarElm = document.querySelector(
        '.st-my-froala-eg-dark-theme .fr-toolbar'
      ) as HTMLElement;
      const secondToolbarElm = document.querySelector(
        '.st-my-froala-eg-dark-theme .fr-second-toolbar'
      ) as HTMLElement;
      const editorElm = document.querySelector(
        '.st-my-froala-eg-dark-theme .fr-wrapper'
      ) as HTMLElement;
      const isDarkTheme = toolbarElm.classList.contains('dark-theme');
      if (isDarkTheme) {
        switcher.classList.add('bg-[#fc4420]');
        switcher.classList.add('before:translate-x-[16px]');
      }
      switcher.addEventListener('click', function() {
        toolbarElm.classList.toggle('dark-theme');
        editorElm.classList.toggle('dark-theme');
        secondToolbarElm.classList.toggle('dark-theme');
        switcher.classList.toggle('bg-[#fc4420]');
        switcher.classList.toggle('before:translate-x-[16px]');
      });
    }
  });
};

export const closeFullScreen = (editor: any) => {
  editor.$('body').on('click', 'button#close-fullscreen', function() {
    if (editor.fullscreen.isActive()) {
      editor.fullscreen.toggle();
      // Remove the prepended HTML
      editor.$tb.find('#custom-title').remove();
    }
  });
};

export const initFullScreenEditor = (editor: any) => {
  openFullScreen(editor);
  closeFullScreen(editor);
};
